<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page

========================================================================================== -->


<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('auth/logOut');
  }
};
</script>
